<template>
  <div class="songChartWrap">
    <div class="mainCom w1200 clearfix">
      <div class="mainComL">
        <menuWX></menuWX>
      </div>
      <div class="mainComR">
        <div class="songChartBar">
          <div class="songCharMain">
            <div class="title">
              <img class="tit" :src="songCharTitle" alt="">
              <span class="back">返回上级</span>
            </div>
            <div class="tagWrap">
              <span v-for="(item,index) in tagList" class="tag" :class="{active:tagActive == index}" :style="{backgroundImage: 'url('+(tagActive == index?tagBg:'')+')'}">{{item}}</span>
            </div>
            <div class="userInfo">
              <div class="item"><span class="tit">游戏昵称：</span><span class="txt">小羊</span></div>
              <div class="item"><span class="tit">游戏大区：</span><span class="txt">1000000000</span></div>
              <div class="item"><span class="tit">情侣昵称：</span><span class="txt">小羊</span></div>
              <div class="item"><span class="tit">情侣爱心：</span><span class="txt">10000</span></div>
              <div class="item"><span class="tit">闺蜜昵称：</span><span class="txt">小羊</span></div>
              <div class="item"><span class="tit">闺蜜鲜花：</span><span class="txt">10000</span></div>
            </div>
            <div class="chartListBar">
              <div class="charTitle">
                <span class="tit">激活歌曲打榜</span>
                <span class="switch">
                  <img :src="isClose" alt="">
                  <b>（打开激活打榜功能）</b>
                </span>
              </div>
              <div class="charTagWrap">
                <div class="tag" v-for="(item,index) in charTagList" :class="{active:charTagActive==index}"><span>{{item}}</span></div>
              </div>
              <div class="searchBar clearfix">
                <div class="selection fl">
                  <el-select v-model="songVal">
                    <el-option
                      v-for="(item,index) in songOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="selection fl">
                  <el-select v-model="modeVal">
                    <el-option
                      v-for="(item,index) in modeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="searchInp fl">
                  <el-input v-model="keyword" placeholder="请输入查询用户昵称"></el-input>
                </div>
                <div class="btnWrap fl">
                  <span class="btn">查询</span>
                </div>
                <div class="selection historyWrap fr">
                  <el-select v-model="historyVal">
                    <el-option
                      v-for="(item,index) in historyOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="tableWrap">
                <el-table
                  style="width: 100%"
                  stripe
                  :data="tableData">
                  <template v-for="(item,index) in tableHead[tagActive]">
                    <el-table-column :prop="item.prop" :label="item.label"></el-table-column>
                  </template>
                </el-table>
              </div>
              <div class="pageWrap txtC">
                <el-pagination
                  background
                  layout="total, prev, pager, next, jumper"
                  :current-page="page"
                  :page-size="size"
                  :total="total"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange">
                </el-pagination>
              </div>
              <div class="illustrateWrap">说明</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import menuWX from '@/components/Menu'

  export default {
    name: 'record',
    components:{
      menuWX,
    },
    data(){
      return{
        songCharTitle:require('@/assets/imgs/songChart/songCharTitle.png'),
        tagBg:require('@/assets/imgs/songChart/tagBg.png'),
        isClose:require('@/assets/imgs/songChart/isClose.png'),

        tagActive:0,
        tagList:['BGM打榜','赛季打榜'],

        charTagActive:0,
        charTagList:['个人全明星','情侣全明星','闺蜜全明星'],

        songVal:0,
        songOptions:[
          {value:0,label:'歌曲'},
          {value:1,label:'戏曲'},
        ],

        modeVal:0,
        modeOptions:[
          {value:0,label:'all'},
          {value:1,label:'single'},
        ],

        keyword:'',

        historyVal:0,
        historyOptions:[
          {value:0,label:'历史赛季'},
          {value:1,label:'当前赛季'},
        ],

        page:1,
        size:20,
        total:100,
        tableHead:[
          [
            {prop:'time',label:'排名'},
            {prop:'name',label:'玩家'},
            {prop:'area',label:'全明星评分'},
            {prop:'icon',label:'家族'},
            {prop:'status',label:'歌曲名'},
            {prop:'mode',label:'模式'},
          ],
        ],
        tableData:[
          {time:'第一名', name:'小羊', area:'1000000000', icon:'羊族', status:'成功',mode:'all'},
          {time:'第一名', name:'小羊', area:'1000000000', icon:'羊族', status:'失败',mode:'single'},
          {time:'第一名', name:'小羊', area:'1000000000', icon:'羊族', status:'成功',mode:'all'},
          {time:'第一名', name:'小羊', area:'1000000000', icon:'羊族', status:'失败',mode:'single'},
          {time:'第一名', name:'小羊', area:'1000000000', icon:'羊族', status:'成功',mode:'all'},
          {time:'第一名', name:'小羊', area:'1000000000', icon:'羊族', status:'失败',mode:'single'},
          {time:'第一名', name:'小羊', area:'1000000000', icon:'羊族', status:'成功',mode:'all'},
          {time:'第一名', name:'小羊', area:'1000000000', icon:'羊族', status:'失败',mode:'single'},
          {time:'第一名', name:'小羊', area:'1000000000', icon:'羊族', status:'成功',mode:'all'},
          {time:'第一名', name:'小羊', area:'1000000000', icon:'羊族', status:'失败',mode:'single'},
          {time:'第一名', name:'小羊', area:'1000000000', icon:'羊族', status:'成功',mode:'all'},
          {time:'第一名', name:'小羊', area:'1000000000', icon:'羊族', status:'失败',mode:'single'},
        ]

      }

    },
    mounted(){
      document.documentElement.scrollTop = 0
    },
    methods: {
      handleSizeChange(){

      },
      handleCurrentChange(){

      },
    }
  }
</script>

<style lang="less">
  .songChartWrap{
    .searchBar{
      .selection{
        margin-right: 20px;
        .el-input__inner{
          padding: 0 10px;
          width: 100px;
          height: 33px;
          line-height: 33px;
          background: none;
          color: #ffffff;
          font-size: 18px;
        }
        .el-input__icon{
          line-height: 33px;
        }
      }
      .historyWrap{
        margin-right: 0;
        .el-input__inner{
          width: 120px;
        }
      }
      .searchInp{
        .el-input__inner{
          padding: 0 10px;
          width: 200px;
          height: 33px;
          line-height: 33px;
          font-size: 18px;
          background: none;
        }
      }
    }
    .tableWrap{
      .el-table{
        background: none!important;
        &::before{
          background: none;
        }
        .cell{
          color: #fff;
          font-size: 18px;
          text-align: center;
        }
        .el-table__cell{
          background: none;
          border:none;
        }
        .is-leaf {
          border: none!important;
        }
        tr{
          background: none!important;
        }
        th{
          .cell{
            font-size: 20px;
            font-weight: 600;
          }
        }
        .el-table__body{
          tr{
            background: rgba(255,255,255,0.1)!important;
          }
          .el-table__row--striped{
            background: none!important;
            td.el-table__cell{
              background: none!important;
            }
          }
        }
      }
    }
  }
</style>
<style lang="less" scoped>
  .songChartWrap{
    padding-bottom: 20px;
    .songChartBar{
      padding:16px;
      background: linear-gradient(180deg, #764DCC 0%, #7E40B2 52%, #311F57 100%);
      box-shadow: 0 2px 8px 0 #EED9FF, 0 0 4px 0 rgba(255,255,255,0.25);
      border: 3px solid #6AF6FF;
      .songCharMain{
        padding-bottom: 20px;
        box-shadow: 0 2px 8px 0 #EED9FF, 0 0 4px 0 rgba(255,255,255,0.25);
        border-radius: 10px;
        border: 3px solid #FF00E6;
        .title{
          margin-top:24px;
          margin-bottom: 13px;
          text-align: center;
          position: relative;
          z-index: 1;
          .back{
            position: absolute;
            right: 32px;
            top:0;
            z-index: 1;
            font-size: 20px;
            font-weight: 400;
            color: #FFFFFF;
            line-height:47px;
            text-decoration: underline;
            opacity: .95;
          }
        }
        .tagWrap{
          text-align: center;
          .tag{
            margin: 0 34px;
            display: inline-block;
            height: 50px;
            font-size: 24px;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 28px;
            cursor: pointer;
          }
          .active{
            color: #6AF6FF;
            background-repeat: no-repeat;
            background-position: center bottom;
            cursor: default;
          }
        }
        .userInfo{
          margin: 6px 11px 26px;
          padding: 25px 100px;
          height: 168px;
          background: rgba(255,255,255,0.1);
          border-radius: 10px;
          color: #ffffff;
          box-sizing: border-box;
          overflow: hidden;
          .item{
            float: left;
            margin-bottom: 10px;
            span{
              display: inline-block;
              font-size: 18px;
              line-height: 30px;
            }
            .tit{
              width: 90px;
            }
            .txt{
              padding: 0 5px;
              width: 218px;
              border-bottom: 1px dashed #FFFFFF;
              box-sizing: border-box;
            }
            &:nth-child(even){
              margin-left: 58px;
            }
          }
        }
        .chartListBar{
          .charTitle{
            margin: 0 20px 12px;
            display: flex;
            align-items: center;
            .tit{
              margin-right: 34px;
              font-size: 24px;
              font-weight: 500;
              color: #6AF6FF;
              line-height: 28px;
            }
            .switch{
              display: flex;
              align-items: center;
              b{
                font-size: 16px;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 19px;
              }
            }
          }
          .charTagWrap{
            margin: 0 10px 10px;
            padding:0 10px;
            border-bottom: 1px solid #ffffff;
            overflow: hidden;
            .tag{
              float: left;
              display: inline-block;
              width: 33%;
              span{
                display: inline-block;
                font-size: 22px;
                font-weight: 400;
                color: #FFFFFF;
                height: 30px;
                line-height: 30px;
                border-bottom: 2px solid transparent;
              }
            }
            .active{
              span{
                border-bottom: 2px solid #6AF6FF;
              }
            }
          }
          .searchBar{
            margin-bottom: 20px;
            padding:0 20px;
            .btnWrap{
              margin:2px 10px;
              width: 50px;
              height: 28px;
              line-height: 28px;
              text-align: center;
              background: #6AF6FF;
              border-radius: 2px 2px 2px 2px;
              cursor: pointer;
              .btn{
                font-size: 12px;
              }
            }
          }
        }
        .tableWrap{
          margin-bottom: 20px;
        }
        .pageWrap{
          margin-bottom: 20px;
        }
        .illustrateWrap{
          margin: 0 10px;
          height: 200px;
          line-height: 200px;
          background: rgba(255,255,255,0.1);
          border-radius: 10px;
          font-size: 20px;
          text-align: center;
          color: #ffffff;
        }
      }
    }
  }
</style>
